
.acme-navbar-text{
  color: white;
}


.acme-navbar-text btn-group {
  margin-top:8px;
}


