/**
 *  If you want to override some bootstrap variables, you have to change values here.
 *  The list of variables are listed here bower_components/bootstrap-sass/assets/stylesheets/bootstrap/_variables.scss
 */
$navbar-inverse-link-color: #5AADBB;
$icon-font-path: "../../bower_components/bootstrap-sass/assets/fonts/bootstrap/";

/**
 *  Do not remove the comments below. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
@import "../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

.thumbnail {
  height: 200px;

  img.pull-right {
    width: 50px;
  }
}

.majeur {
  background-color: #00DD00;
}

.mineur {
  background-color: #f7e741;
}

.nav-active a, .nav-active a span
{
  font-weight: bold;
  color: #07d2be !important;
}

.histo tbody td
{
  white-space: pre;
}

.histo thead th
{
  vertical-align: top !important;
}

.tronc_queteur_deleted
{
  color:lightgrey;
}


.big-icon
{
  font-size:20px;
}

.goal_partial_amount
{
  margin-left:8px;
  font-size: 12px;
  font-weight: bold;
}

.oddRows
{
  background-color: #e8e8e8 !important;
}
.oddRows td, .evenRows td
{
  vertical-align: middle !important;
}


.tronc-queteur-print
{
  display:none;
}
.pendingQueteurCount span
{
  background-color:red;
}

.pendingQueteurCountZero span
{
  background-color:silver;
}


.pendingQueteurCount span:hover
{
  background-color:blue;
}

.queteur-status, .queteur-status span
{
  height: 20px !important;
}

@media print
{
 .tronc-queteur
 {
   display:none;
 }

 .tronc-queteur-print
 {
   display:block;
   margin:20px;
   text-align: right;

 }
  .tronc-queteur-print div
  {
    text-align: right;
    margin-right:45px;
  }

  .tronc-queteur-print table
  {
    width:490px;
    border:2px solid silver;
    margin-left:75px;
  }


  .tronc-queteur-print table thead tr:nth-child(1)
  {
    font-size: larger;
    font-weight:bolder;
    border:2px solid silver;
  }
  .tronc-queteur-print table thead tr:nth-child(2)
  {
    border:2px solid silver;
  }

  .tronc-queteur-print table thead tr:nth-child(1) th, .tronc-queteur-print table thead tr:nth-child(2) td
  {
    padding:6px;
  }

  .tronc-queteur-print table tbody tr:nth-child(1)
  {
    font-weight:bold;
    border:2px solid silver;
  }

  .tronc-queteur-print table tbody td:nth-child(1)
  {
    width:160px;
  }
  .tronc-queteur-print table tbody td:nth-child(2)
  {
    width:160px;
  }

  .tronc-queteur-print table tbody tr
  {
    border:1px solid silver;
  }

  .tronc-queteur-print table th, .tronc-queteur-print table td
  {
    margin:4px;
  }
  .tronc-queteur-print td:nth-child(even), .tronc-queteur-coins td:nth-child(even)
  {
    padding:4px;
  }

  .tronc-queteur-print td:nth-child(odd), .tronc-queteur-coins td:nth-child(odd)
  {
    padding:4px;
  }

  .tronc-queteur-print tfoot tr
  {
    border:1px solid silver;
    height:40px;
  }
  .tronc-queteur-print tfoot td
  {
    text-align: left;
    font-size:10px;
  }
  .tronc-queteur-print tfoot th
  {
    font-weight: bold;
    padding-left:5px;
  }

  @page {
    margin: 0.1cm;
  }
}

/**
 *  Do not remove the comments below. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "components/malarkey/malarkey.scss";
@import "components/navbar/navbar.scss";
// endinjector
